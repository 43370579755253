const AppStore = ({ width = 132, height = 35, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 162 50"
      fill="none"
      {...props}
    >
      <path
        d="M154.722 48.7725H6.73959C3.48399 48.7725 0.820312 46.0853 0.820312 42.8009V6.97156C0.820312 3.6872 3.48399 1 6.73959 1H154.722C157.977 1 160.641 3.6872 160.641 6.97156V42.8009C160.641 46.0853 157.977 48.7725 154.722 48.7725Z"
        fill="black"
      />
      <path
        d="M154.722 48.7725H6.73959C3.48399 48.7725 0.820312 46.0853 0.820312 42.8009V6.97156C0.820312 3.6872 3.48399 1 6.73959 1H154.722C157.977 1 160.641 3.6872 160.641 6.97156V42.8009C160.641 46.0853 157.977 48.7725 154.722 48.7725"
        stroke="white"
      />
      <path
        d="M35.171 25.5822C35.2012 23.2412 36.4306 21.0789 38.4284 19.8527C37.1624 18.0477 35.1178 16.9442 32.9115 16.8753C30.5903 16.6321 28.34 18.2619 27.1572 18.2619C25.9514 18.2619 24.1303 16.8995 22.1692 16.9398C19.5889 17.023 17.2416 18.452 15.9863 20.704C13.3129 25.3245 15.307 32.115 17.8679 35.8499C19.1491 37.6788 20.6465 39.7216 22.6058 39.6492C24.5232 39.5698 25.2392 38.4287 27.5536 38.4287C29.8465 38.4287 30.5183 39.6492 32.5174 39.6031C34.5748 39.5698 35.871 37.7662 37.1073 35.92C38.0279 34.6169 38.7363 33.1766 39.2062 31.6526C36.7627 30.6209 35.1738 28.2307 35.171 25.5822Z"
        fill="white"
      />
      <path
        d="M30.9234 13.9474C32.0198 12.6366 32.5599 10.9519 32.4291 9.25098C30.7541 9.42618 29.2068 10.2234 28.0955 11.4839C26.9983 12.7275 26.4424 14.3544 26.5503 16.0064C28.2479 16.0238 29.8596 15.2649 30.9234 13.9474Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8199 33.5006H59.9314L61.3978 37.8342H64.0306L58.2418 21.8008H55.5524L49.7637 37.8342H52.3523L53.8199 33.5006ZM59.2975 31.499H54.4531L56.8426 24.4658H56.9094L59.2975 31.499Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1759 37.5517C74.11 37.5517 76.0543 35.2178 76.0543 31.5852C76.0543 27.9626 74.0986 25.6174 71.1318 25.6174C69.6052 25.5682 68.1751 26.3622 67.4097 27.684H67.3656V25.7397H65.043V41.2965H67.4424V35.5065H67.498C68.2308 36.8366 69.6593 37.631 71.1759 37.5517ZM70.5001 27.6641C72.3662 27.6641 73.5892 29.22 73.5892 31.5867C73.5892 33.9748 72.3662 35.5194 70.5001 35.5194C68.6667 35.5194 67.4336 33.942 67.4336 31.5867C67.4336 29.2528 68.6667 27.6641 70.5001 27.6641Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0412 37.5517C86.9753 37.5517 88.9195 35.2178 88.9195 31.5852C88.9195 27.9626 86.9639 25.6174 83.997 25.6174C82.4704 25.5682 81.0403 26.3622 80.2749 27.684H80.2307V25.7397H77.9082V41.2965H80.3077V35.5065H80.3632C81.096 36.8366 82.5246 37.631 84.0412 37.5517ZM83.3653 27.6641C85.2314 27.6641 86.4545 29.22 86.4545 31.5867C86.4545 33.9748 85.2314 35.5194 83.3653 35.5194C81.532 35.5194 80.2988 33.942 80.2988 31.5867C80.2988 29.2528 81.532 27.6641 83.3653 27.6641Z"
        fill="white"
      />
      <path
        d="M97.4211 32.9643C97.5989 34.5543 99.1435 35.5982 101.254 35.5982C103.277 35.5982 104.732 34.5542 104.732 33.1206C104.732 31.8761 103.854 31.131 101.776 30.6203L99.6982 30.1197C96.7541 29.4086 95.3873 28.0317 95.3873 25.7974C95.3873 23.031 97.7981 21.1309 101.221 21.1309C104.609 21.1309 106.932 23.031 107.01 25.7974H104.588C104.443 24.1973 103.12 23.2315 101.187 23.2315C99.2544 23.2315 97.9317 24.2087 97.9317 25.631C97.9317 26.7645 98.7765 27.4315 100.843 27.9421L102.61 28.3759C105.899 29.1538 107.266 30.4753 107.266 32.8205C107.266 35.8201 104.877 37.6988 101.076 37.6988C97.5207 37.6988 95.1199 35.8642 94.9648 32.9642L97.4211 32.9643Z"
        fill="white"
      />
      <path
        d="M112.448 22.9736V25.74H114.671V27.6402H112.448V34.0845C112.448 35.0857 112.893 35.5522 113.871 35.5522C114.135 35.5476 114.398 35.5291 114.66 35.4967V37.3855C114.221 37.4676 113.774 37.5048 113.327 37.4964C110.961 37.4964 110.038 36.6075 110.038 34.3405V27.6402H108.338V25.74H110.038V22.9736H112.448Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.503 25.5977C118.125 25.5977 115.959 27.9089 115.959 31.5868C115.959 35.2749 118.104 37.576 121.503 37.576C124.904 37.576 127.048 35.2749 127.048 31.5868C127.048 27.9088 124.892 25.5977 121.503 25.5977ZM121.502 27.5742C123.446 27.5742 124.602 29.0633 124.602 31.5863C124.602 34.1195 123.446 35.5972 121.502 35.5972C119.557 35.5972 118.402 34.1195 118.402 31.5863C118.402 29.0747 119.557 27.5742 121.502 27.5742Z"
        fill="white"
      />
      <path
        d="M129.021 25.7396H131.31V27.7293H131.366C131.685 26.4484 132.858 25.5669 134.177 25.6173C134.454 25.6163 134.73 25.6464 134.999 25.7068V27.9512C134.65 27.8445 134.286 27.7955 133.921 27.8062C133.216 27.7776 132.533 28.0587 132.052 28.5758C131.572 29.0928 131.341 29.7942 131.421 30.4956V37.4292H129.021V25.7396Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.031 37.576C143.675 37.576 145.742 36.1197 146.064 33.9977L143.786 33.9976C143.349 35.0823 142.251 35.7509 141.086 35.6418C140.213 35.674 139.368 35.3295 138.767 34.696C138.165 34.0625 137.865 33.201 137.942 32.3308V32.1858H146.198V31.3637C146.198 27.8193 144.164 25.5977 140.93 25.5977C137.642 25.5977 135.52 27.9757 135.52 31.6423C135.52 35.2976 137.63 37.576 141.031 37.576ZM143.795 30.5085H137.951C137.949 29.7193 138.262 28.962 138.821 28.4048C139.381 27.8477 140.139 27.537 140.928 27.5417C141.711 27.5248 142.466 27.8354 143.01 28.3986C143.554 28.9618 143.839 29.7265 143.795 30.5085Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6264 10.3841C55.9241 9.64254 54.9264 9.25499 53.9078 9.32809H51.125V17.0321H53.9078C56.2026 17.0321 57.5328 15.6174 57.5328 13.1561C57.6612 12.143 57.3286 11.1256 56.6264 10.3841ZM53.7729 15.9456H52.3203V10.4198H53.7729C54.497 10.3796 55.2011 10.6651 55.6928 11.1982C56.1844 11.7313 56.4121 12.4563 56.3135 13.1748C56.4198 13.8963 56.1954 14.6272 55.7025 15.1648C55.2097 15.7023 54.5008 15.9891 53.7729 15.9456Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.1717 11.5185C59.2822 12.0705 58.7825 13.079 58.8822 14.1211C58.7806 15.1643 59.2798 16.1748 60.1701 16.728C61.0604 17.2811 62.1875 17.2811 63.0778 16.728C63.9681 16.1748 64.4673 15.1643 64.3657 14.1211C64.4654 13.079 63.9657 12.0705 63.0762 11.5185C62.1867 10.9665 61.0612 10.9665 60.1717 11.5185ZM61.6229 12.125C62.6164 12.125 63.1826 12.862 63.1826 14.1222C63.1826 15.3875 62.6165 16.1239 61.6229 16.1239C60.6255 16.1239 60.0645 15.3926 60.0645 14.1222C60.0645 12.862 60.6255 12.125 61.6229 12.125Z"
        fill="white"
      />
      <path
        d="M71.6561 17.0307H70.4658L69.2642 12.7488H69.1734L67.9768 17.0307H66.7979L65.1953 11.2168H66.3591L67.4006 15.6532H67.4863L68.6817 11.2168H69.7824L70.9777 15.6532H71.0685L72.1049 11.2168H73.2524L71.6561 17.0307Z"
        fill="white"
      />
      <path
        d="M74.6035 11.2166H75.7081V12.1402H75.7938C76.092 11.4602 76.7887 11.0442 77.5288 11.1044C78.1018 11.0613 78.6633 11.2811 79.0548 11.7018C79.4463 12.1225 79.6252 12.6983 79.5411 13.2668V17.0305H78.3937V13.5549C78.3937 12.6206 77.9877 12.156 77.1392 12.156C76.7505 12.1379 76.3733 12.2904 76.1064 12.5736C75.8396 12.8568 75.7097 13.2424 75.7509 13.6293V17.0305H74.6035V11.2166Z"
        fill="white"
      />
      <path d="M81.3672 8.94629H82.5146V17.0298H81.3672V8.94629Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.3984 11.5185C84.5088 12.0705 84.0091 13.079 84.1087 14.1212C84.0072 15.1645 84.5066 16.1749 85.3969 16.7281C86.2872 17.2812 87.4143 17.2812 88.3047 16.7281C89.195 16.1749 89.6944 15.1645 89.5929 14.1212C89.6925 13.079 89.1927 12.0705 88.3032 11.5185C87.4136 10.9665 86.288 10.9665 85.3984 11.5185ZM86.8494 12.125C87.843 12.125 88.4091 12.862 88.4091 14.1222C88.4091 15.3875 87.843 16.1239 86.8494 16.1239C85.8521 16.1239 85.291 15.3926 85.291 14.1222C85.291 12.862 85.8521 12.125 86.8494 12.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.9651 13.6508C91.582 13.7366 90.8027 14.3399 90.8027 15.3864C90.8047 15.8827 91.0164 16.3549 91.3857 16.6864C91.7549 17.0179 92.2472 17.1778 92.7407 17.1264C93.4448 17.159 94.1119 16.8103 94.487 16.2136H94.5778V17.0306H95.6824V13.0582C95.6824 11.8301 94.8603 11.1045 93.4027 11.1045C92.0838 11.1045 91.1444 11.745 91.0272 12.7436H92.138C92.2654 12.3326 92.7092 12.0974 93.3497 12.0974C94.134 12.0974 94.54 12.4442 94.54 13.0582V13.5601L92.9651 13.6508ZM94.5406 14.4033V14.8894C94.5155 15.2599 94.3411 15.6044 94.0573 15.844C93.7735 16.0836 93.4046 16.1978 93.0351 16.1603C92.4109 16.1603 91.957 15.8558 91.957 15.3326C91.957 14.82 92.3202 14.5477 93.1208 14.4941L94.5406 14.4033Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.6008 11.1194C98.1319 11.1194 97.1875 12.2832 97.1875 14.1203C97.1875 15.9619 98.1218 17.1257 99.6008 17.1256C100.351 17.1533 101.054 16.7625 101.427 16.1112H101.517V17.0298H102.617V8.94629H101.469V12.1394H101.384C101.037 11.4827 100.343 11.0853 99.6008 11.1194ZM99.9245 16.0928C98.9524 16.0928 98.3711 15.3507 98.3711 14.1176H98.3711C98.3711 12.8945 98.9587 12.1475 99.9245 12.1475C100.885 12.1475 101.489 12.9109 101.489 14.1226C101.489 15.34 100.892 16.0928 99.9245 16.0928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.656 11.5185C107.767 12.0705 107.267 13.079 107.367 14.1211C107.265 15.1643 107.764 16.1748 108.654 16.728C109.545 17.2811 110.672 17.2811 111.562 16.728C112.452 16.1748 112.952 15.1643 112.85 14.1211C112.95 13.079 112.45 12.0705 111.561 11.5185C110.671 10.9665 109.546 10.9665 108.656 11.5185ZM110.107 12.125C111.101 12.125 111.667 12.862 111.667 14.1222C111.667 15.3875 111.101 16.1239 110.107 16.1239C109.11 16.1239 108.549 15.3926 108.549 14.1222C108.549 12.862 109.11 12.125 110.107 12.125Z"
        fill="white"
      />
      <path
        d="M114.393 11.2166H115.497V12.1402H115.583C115.881 11.4602 116.578 11.0442 117.318 11.1044C117.891 11.0613 118.452 11.2811 118.844 11.7018C119.235 12.1225 119.414 12.6983 119.33 13.2668V17.0305H118.183V13.5549C118.183 12.6206 117.777 12.156 116.928 12.156C116.54 12.1379 116.162 12.2904 115.896 12.5736C115.629 12.8568 115.499 13.2424 115.54 13.6293V17.0305H114.393V11.2166Z"
        fill="white"
      />
      <path
        d="M125.812 9.76855V11.2425H127.071V12.209H125.812V15.1986C125.812 15.8076 126.063 16.0742 126.634 16.0742C126.78 16.0738 126.926 16.0649 127.071 16.0478V17.0035C126.865 17.0404 126.657 17.06 126.447 17.0621C125.171 17.0621 124.663 16.6132 124.663 15.4923V12.2089H123.74V11.2425H124.663V9.76855H125.812Z"
        fill="white"
      />
      <path
        d="M128.639 8.94629H129.776V12.1502H129.867C130.179 11.4642 130.888 11.0477 131.64 11.1087C132.209 11.0777 132.763 11.3024 133.15 11.7215C133.538 12.1407 133.718 12.7106 133.642 13.2762V17.0298H132.493V13.5592C132.493 12.6306 132.061 12.1603 131.25 12.1603C130.851 12.1275 130.458 12.2727 130.175 12.5568C129.893 12.841 129.751 13.2353 129.786 13.6342V17.0298H128.639L128.639 8.94629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.81 17.143C138.946 17.2662 140.008 16.5576 140.329 15.4609L139.225 15.4609C138.949 15.9547 138.403 16.2331 137.842 16.1657C137.409 16.1705 136.994 15.9925 136.699 15.6754C136.404 15.3583 136.257 14.9316 136.293 14.5002V14.4359H140.399V14.0355C140.399 12.2098 139.423 11.1046 137.805 11.1046C137.028 11.098 136.286 11.4289 135.772 12.0117C135.257 12.5944 135.021 13.3717 135.124 14.1421C135.018 14.9103 135.255 15.6863 135.773 16.2642C136.29 16.8421 137.035 17.1634 137.81 17.143ZM139.232 13.5846H136.295C136.29 13.1837 136.447 12.7977 136.73 12.5141C137.014 12.2305 137.4 12.0735 137.801 12.0785C138.196 12.0694 138.577 12.2278 138.85 12.5146C139.122 12.8015 139.261 13.19 139.232 13.5846Z"
        fill="white"
      />
    </svg>
  );
};

export default AppStore;
