import AppStore from "@/components/icons/app-stores/app-store";
import HuaweiStore from "@/components/icons/app-stores/huawei-store";
import GooglePlayStore from "@/components/icons/app-stores/play-store";
import MT4HuaweiQRCode from "@/components/icons/qr-codes/mt4-huawei-qr-code";
import MT4iosQRCode from "@/components/icons/qr-codes/mt4-ios-qr-code";
import MT4PlayStoreQRCode from "@/components/icons/qr-codes/mt4-playstore-qr-code";
import MT5HuaweiQRCode from "@/components/icons/qr-codes/mt5-huawei-qr-code";
import MT5iosQRCode from "@/components/icons/qr-codes/mt5-ios-qr-code";
import MT5PlayStoreQRCode from "@/components/icons/qr-codes/mt5-playstore-qr-code";
import Link from "@/components/utility/link";
import usePlatformDetector from "@/hooks/usePlatformDetector";
import { formatTextWithLineBreaks } from "@/utils/component-helpers";
import { cn, getRouteDetails } from "@/utils/helpers";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

const linksAndCodes: Record<
  "mt4" | "mt5",
  {
    links: {
      PlayStore: string;
      AppStore: string;
      HuaweiStore: string;
    };
    codes: {
      PlayStore: JSX.Element;
      AppStore: JSX.Element;
      HuaweiStore: JSX.Element;
    };
  }
> = {
  mt4: {
    links: {
      PlayStore: "https://download.mql5.com/cdn/mobile/mt4/android",
      AppStore: "https://download.mql5.com/cdn/mobile/mt4/ios",
      HuaweiStore: "https://download.mql5.com/cdn/mobile/mt4/android/app-gallery",
    },
    codes: {
      PlayStore: <MT4PlayStoreQRCode />,
      AppStore: <MT4iosQRCode />,
      HuaweiStore: <MT4HuaweiQRCode />,
    },
  },

  mt5: {
    links: {
      PlayStore: "https://download.mql5.com/cdn/mobile/mt5/android",
      AppStore: "https://download.mql5.com/cdn/mobile/mt5/ios",
      HuaweiStore: "https://download.mql5.com/cdn/mobile/mt5/android/app-gallery",
    },
    codes: {
      PlayStore: <MT5PlayStoreQRCode />,
      AppStore: <MT5iosQRCode />,
      HuaweiStore: <MT5HuaweiQRCode />,
    },
  },
};

const QRCodes = ({ app }: { app: "mt4" | "mt5" }) => {
  const [platform, setPlatform] = useState<"AppStore" | "PlayStore" | "HuaweiStore">(
    "PlayStore"
  );
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const scrollTimeout = useRef<NodeJS.Timeout>();

  const { isRTL } = getRouteDetails(router);

  useEffect(() => {
    if (ref.current) {
      // Calculate scroll position based on platform
      let targetScroll = 0;
      switch (platform) {
        case "PlayStore":
          targetScroll = 0;
          break;
        case "AppStore":
          targetScroll = ref.current.scrollWidth / 3;
          break;
        case "HuaweiStore":
          targetScroll = (ref.current.scrollWidth / 3) * 2;
          break;
      }

      ref.current.scrollTo({
        left: targetScroll,
        behavior: "smooth",
      });
    }
  }, [platform]);

  const handleScroll = () => {
    if (!ref.current) return;

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = setTimeout(() => {
      if (!ref.current) return;

      const scrollLeft = ref.current.scrollLeft;
      const scrollWidth = ref.current.scrollWidth;
      const viewportWidth = ref.current.offsetWidth;
      const sectionWidth = scrollWidth / 3;

      // Determine which section we're closest to
      const section = Math.round(scrollLeft / sectionWidth);
      let newPlatform: "AppStore" | "PlayStore" | "HuaweiStore";

      if (section === 0) {
        newPlatform = "PlayStore";
      } else if (section === 1) {
        newPlatform = "AppStore";
      } else {
        newPlatform = "HuaweiStore";
      }

      // Only update if platform changed
      if (newPlatform !== platform) {
        setPlatform(newPlatform);
      }

      // Snap to the nearest QR code
      let targetScroll = section * sectionWidth;

      ref.current.scrollTo({
        left: targetScroll,
        behavior: "smooth",
      });
    }, 150);
  };

  return (
    <div className="flex">
      <div className="-ms-[5px] relative w-min flex justify-between flex-col mb-1">
        <Link href={linksAndCodes[app].links.PlayStore}>
          <GooglePlayStore
            onMouseEnter={() => setPlatform("PlayStore")}
            width={132}
            height={37}
            className={cn("cursor-pointer")}
          />
        </Link>
        <Link href={linksAndCodes[app].links.AppStore}>
          <AppStore
            onMouseEnter={() => setPlatform("AppStore")}
            width={132}
            height={37}
            className={cn("cursor-pointer")}
          />
        </Link>
        <Link href={linksAndCodes[app].links.HuaweiStore}>
          <HuaweiStore
            onMouseEnter={() => setPlatform("HuaweiStore")}
            width={121}
            height={36.35}
            className={cn("cursor-pointer")}
          />
        </Link>
      </div>
      <div
        ref={ref}
        dir="ltr"
        className="border border-solid border-azure-100 bg-white-100 flex h-[130px] w-[130px] overflow-x-scroll hide-scrollbar snap-x snap-mandatory"
        onScroll={handleScroll}
      >
        <div className="min-w-[130px] h-full snap-center">
          <div className="mb-0 text-center flex items-center justify-center h-full">
            {linksAndCodes[app].codes.PlayStore}
          </div>
        </div>
        <div className="min-w-[130px] h-full snap-center">
          <div className="mb-0 text-center flex items-center justify-center h-full">
            {linksAndCodes[app].codes.AppStore}
          </div>
        </div>
        <div className="min-w-[130px] h-full snap-center">
          <div className="mb-0 text-center flex items-center justify-center h-full">
            {linksAndCodes[app].codes.HuaweiStore}
          </div>
        </div>
      </div>
    </div>
  );
};

const AdaptiveMTQRCode = ({
  data,
  debug,
  app = data?.fields?.options?.platform || "mt4",
  qrText = data?.fields?.options?.qrText,
  ...props
}: {
  data: any;
  debug: any;
  app?: "mt4" | "mt5";
  qrText?: string;
}) => {
  const environment = usePlatformDetector();

  // Show QR code on Desktop
  if (environment.device === "Desktop") {
    return (
      <div {...props} className="2xl:flex min-w-[300px] w-full">
        <div>
          {app === "mt4" && <QRCodes app={"mt4"} />}
          {app === "mt5" && <QRCodes app={"mt5"} />}
        </div>
        {qrText && (
          <p className="max-w-xs text-sm mt-2 lg:mt-4 lg:ms-2">
            {formatTextWithLineBreaks(qrText)}
          </p>
        )}
      </div>
    );
  }

  // Show App Store on Mobile iOS
  if (environment.device === "Mobile" && environment.platform === "iOS")
    return (
      <Link {...props} href={linksAndCodes[app].links.AppStore}>
        <AppStore width={132} height={37} className="cursor-pointer" />
      </Link>
    );

  // Show Play Store on Mobile Android
  if (environment.device === "Mobile" && environment.platform === "Android")
    return (
      <div
        {...props}
        className="relative -ms-2 flex items-center lg:items-start flex-col xl:flex-row gap-2"
      >
        <Link href={linksAndCodes[app].links.PlayStore}>
          <GooglePlayStore width={132} height={37} className="cursor-pointer" />
        </Link>
        <Link href={linksAndCodes[app].links.HuaweiStore}>
          <HuaweiStore width={121} height={36.35} className="cursor-pointer" />
        </Link>
      </div>
    );
};

export default AdaptiveMTQRCode;
